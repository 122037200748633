<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div>
        <h2>Novo {{ label }}</h2>
        <div class="m-b-xs m-t-xs" v-if="entidade">
          Registro relacionada à entidade <span class="capitalize">{{ entidade }}</span> com ID {{ entidadeId }}
        </div>
        <div class="m-b-xs m-t-xs" v-if="tags">
          <span v-if="tags && tags.length">Tag(s): {{ tags.join(', ') }}</span><span v-else>Tag: {{ tags }}</span>
        </div>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content bg-grey-1" v-if="conta.movimentacao">
        <e-row v-if="conta.movimentacao.parcelado" class="m-b-n-sm">
          <e-col class="flex items-center">
            <div class="bg-grey-2 font-14 text-center wrapper-sm m-r">Parcela <strong>{{conta.parcela}}</strong> <small>de {{conta.parcelas}}</small></div> <div>Este lançamento faz parte de um <span class="text-info font-bold">parcelamento</span> de um lançamento principal ID <a @click="windowConta(conta.movimentacao.id)">#{{conta.movimentacao.id}}</a> no valor de R$ {{conta.movimentacao.valor|moeda}}</div>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do lançamento</legend>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 240px; min-height: 32px">
            <erp-s-field view="ll" :label="!conta.pago ? 'Pagamento pendente' : 'Pago'">
              <sl-checkbox no-yes-no-val v-model="conta.pago" id="pago"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <div v-show="conta.pago" class="pago-row">
          <e-row mr>
            <e-col>
              <erp-s-field :disabled="!conta.pago" label="Data pagamento">
                <datetime-input :disable="!conta.pago" input-class="app-input" date-type="date"
                                date-format="##/##/####"
                                v-model.lazy="conta.dataPagamento"
                                simpleBorder/>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field :disabled="!conta.pago" label="Descontos / Taxas">
                <erp-input :disable="!conta.pago" placeholder="R$" v-model.lazy="conta.descontos" v-money="money"/>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field :disabled="!conta.pago" label="Juros / Multa">
                <erp-input :disable="!conta.pago" placeholder="R$" v-model.lazy="conta.jurosMulta" v-money="money"/>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field :disabled="!conta.pago" label="Valor pago">
                <erp-input :disable="!conta.pago" placeholder="R$" v-model.lazy="conta.valorPago" v-money="money"
                           class="font-bold"/>
              </erp-s-field>
            </e-col>
          </e-row>
        </div>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Descrição">
                  <fin-descricao-input autofocus v-model="conta.descricao" placeholder="Descreva seu lançamento"/>
                  <!--                <erp-input :placeholder="'Defina um nome para seu ' + label.toLowerCase()" v-model="conta.descricao"/>-->
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 300px">
                <erp-s-field :label="labelTipoPessoa">
                  <fin-pessoa-input :placeholder="'Digite um ' + labelTipoPessoa.toLowerCase()" v-model="conta.pessoa"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 200px">
                <erp-s-field required label="Valor">
                  <erp-input placeholder="R$" v-model.lazy="conta.valor" v-money="money"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row class="m-t-xs" mr>
              <e-col style="max-width: 200px">
                <erp-s-field required label="Data competência">
                  <datetime-input input-class="app-input" date-type="date" date-format="##/##/####"
                                  v-model.lazy="conta.dataCompetencia"
                                  simpleBorder/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 200px">
                <erp-s-field label="Código de referência"
                             icon-help="Código informado manualmente para identificação do lançamento. Exceto quando originado por uma venda, neste caso o código é automaticamente preenchido com o número da venda">
                  <erp-input v-model.lazy="conta.numeroDoc"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 154px">
                <erp-s-field label="Dedutível?" icon-help="Se este lançamento é ou não dedutível no imposto de renda">
                  <erp-select v-model="conta.dedutivel" :options="[
                {label: 'Sim', value: true},
                {label: 'Não', value: false},
            ]"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Processo">
                  <global-processo-input v-model="conta.processo"/>
                  <div v-if="conta.processo"><a
                      @click="gotoProcesso(conta.processo && conta.processo.id ? conta.processo : {id: conta.processo})">Ver
                    processo</a></div>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr class="m-t">
              <e-col style="max-width: 90px;">
                <erp-s-field label="Habilitar rateio">
                  <sl-checkbox class="m-t-xs" v-model="conta.rateio" id="rateio"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field required label="Categoria" :icon-help="conta.rateio ? 'Em caso de rateio, escolha uma categoria principal para o lançamento principal.' : ''">
                  <categoria-hirerarquia-select :disable__="conta.rateio" :tipo="tipoInt" v-model="conta.categoria"
                                                :expand="$appConfig.isConfigTrue('financeiro.categoriasExpandidas')"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 300px">
                <erp-s-field label="Centro de custo">
                  <centro-custo-input placeholder="Digite um centro de custo" v-model="conta.centroCusto"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <div v-if="conta.rateio">
              <table class="sl-table-config pad2 nocaps">
                <thead>
                <th><erp-s-field label="Descrição" only-label required /></th>
                <th><erp-s-field label="Categoria" only-label required /></th>
                <th style="width: 180px; max-width: 180px"><erp-s-field label="Valor" only-label required /></th>
                <th style="width: 150px; max-width: 150px"><erp-s-field label="Porcentagem" only-label /></th>
                <th class="text-center" style="max-width: 50px; width: 50px"></th>
                </thead>
                <tbody><!---->
                <tr v-for="(detalhe, key) in conta.detalhes" :key="'detalhe-' + key">
                  <td><fin-descricao-input v-model="detalhe.descricao" placeholder="Descreva o lançamento"/></td>
                  <td>
                    <categoria-hirerarquia-select :tipo="tipoInt" v-model="detalhe.categoria"
                                                  :expand="$appConfig.isConfigTrue('financeiro.categoriasExpandidas')"/>
                  </td>
                  <td style="width: 180px; max-width: 180px">
                    <div class="erp-input"><detalhe-valor-input v-model="detalhe.valor" @change="(e) => defineValorDetalhe(detalhe, key)" /></div>
                  </td>
                  <td style="width: 150px; max-width: 150px">
                    <div class="flex justify-between content-between no-wrap input-with-btn">
                      <div class="erp-input" style="margin-right: -2px"><input v-money="percent" v-model.lazy="detalhe.porcentagem" @keydown="(e) => definePorcentagemDetalhe(detalhe, key)" /></div>
<!--                      <erp-input placeholder="%" v-model.lazy="detalhe.porcentagem" @keyup="(e) => definePorcentagem(detalhe)" v-money="percent" style="margin-right: -2px" />-->
                      <u-btn no-caps flat class="btn-modern b-radius-right no-border-left btn-group text-nowrap" label="%" />
                    </div>
                  </td>
                  <td style="max-width: 50px; width: 50px">
                    <a @click="removerDetalheRateio(detalhe)" class="erp-btn-table-options cursor-pointer">
                      <i class="fal fa-times"/>
                      <u-tooltip :offset="[5,5]">Remover</u-tooltip>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="m-t-sm">
                <e-btn @click="adicionarRateio"><i class="fal fa-plus m-r-sm"></i> Adicionar rateio</e-btn>
              </div>
              <legend></legend>
              <e-row class="resumo-rateio">
                <e-col class="flex items-center justify-end">
                  <div>
                    <div class="m-b text-center"><erp-s-field label="Restante do rateio" only-label /></div>
                    <div class="rateio-lbl"><span :class="{'text-positive': restanteTotalRateio === 0}">{{rateioPctRestanteFormatado}}% | <small>R$</small> {{restanteTotalRateio|moeda}}</span></div>
                  </div>
                  <div class="m-l-xl">
                    <div class="m-b"><erp-s-field label="Total rateado" only-label /></div>
                    <div class="rateio-lbl text-right"><small>R$</small> {{somaTotalRateio|moeda}}</div>
                  </div>
                </e-col>
              </e-row>
            </div>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="m-b">
          <e-col class="flex items-center" style="max-width: 240px; min-height: 32px">
            <erp-s-field view="ll" label="Repetir lançamento?">
              <sl-checkbox v-model="conta.repetir" id="repetir"/>
            </erp-s-field>
          </e-col>
          <e-col v-show="conta.repetir" class="flex items-center">
            <erp-select :disable="!conta.repetir" :options="repetirTypes" v-model="conta.repetirIntervalo"
                        style="max-width: 200px"/>
            <span class="m-l-lg m-r">Ocorrências</span>
            <erp-input :disable="!conta.repetir" v-model="conta.repetirOcorrencias" style="max-width: 80px"/>
            <span class="m-l">vezes</span>
            <u-icon name="help" class="m-l font-14 text-blue-grey-5">
              <u-tooltip :offset="[2,2]">Quantas vezes este registro deve se repetir. Deixe 0 ou em branco para sem limite.</u-tooltip>
            </u-icon>
          </e-col>
        </e-row>
        <legend>Condição de pagamento</legend>
        <e-row mr>
          <e-col v-show="!conta.repetir" style="max-width: 130px">
            <erp-s-field label="Parcelamento" required>
              <erp-select v-model="conta.parcelado" :options="[
                  {label: 'À vista', value: false},
                  {label: 'Parcelado', value: true}
            ]"/>
            </erp-s-field>
          </e-col>
          <e-col v-if="conta.parcelado" style="max-width: 130px">
            <erp-s-field label="Parcelas" required>
              <erp-input type="number" v-model="conta.parcelas" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px">
            <erp-s-field required :label="conta.parcelado ? '1º Vencimento' : 'Vencimento'">
              <datetime-input input-class="app-input" date-type="date" date-format="##/##/####"
                              v-model.lazy="conta.dataVencimento"
                              simpleBorder/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 250px">
            <erp-s-field label="Forma de pagamento">
              <forma-pagamento-input v-model="conta.formaPagamento"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px">
            <erp-s-field label="Conta">
              <banco-input v-model="conta.conta"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <div v-if="conta.parcelado">
          <table class="sl-table-config pad2 nocaps">
            <thead>
            <th style="width: 100px; max-width: 100px" class="text-right flex justify-end"><erp-s-field view="lr" label="Parcela" only-label /></th>
            <th style="max-width: 200px; width: 200px"><erp-s-field label="Vencimento" only-label required /></th>
            <th style="width: 180px; max-width: 180px"><erp-s-field label="Valor" only-label required /></th>
            <th></th>
<!--            <th style="width: 150px; max-width: 150px"><erp-s-field label="Juros" only-label /></th>-->
            <th class="text-center" style="max-width: 50px; width: 50px"></th>
            </thead>
            <tbody><!---->
            <tr v-for="(parcela, key) in cache.parcelas" :key="'parcela-' + key">
              <td style="width: 100px; max-width: 100px" class="text-right"><strong>{{key+1}}</strong> <span class="font-thin font-12">de {{cache.parcelas.length}}</span></td>
              <td style="max-width: 200px; width: 200px">
                <datetime-input v-if="key > 0" input-class="app-input" date-type="date" date-format="##/##/####"
                                v-model.lazy="parcela.vencimento"
                                simpleBorder/>
                <datetime-input v-else input-class="app-input" date-type="date" date-format="##/##/####"
                                v-model.lazy="conta.dataVencimento"
                                simpleBorder/>
              </td>
              <td style="width: 180px; max-width: 180px">
                <div class="erp-input"><input v-money="money" v-model="parcela.valor" /></div>
              </td>
              <td>
                <div class="flex items-center ">
                  <sl-checkbox v-model="parcela.pago" class="m-r" yes-val="" no-val="Não pago" /> <span v-if="parcela.dataPagamento" class="font-12">Pago em {{ parcela.dataPagamento|formatDate('dd/MM/yyyy') }}</span>
                </div>
              </td>
              <td style="max-width: 50px; width: 50px">
                <a @click="removerParcela(parcela)" class="erp-btn-table-options cursor-pointer">
                  <i class="fal fa-times"/>
                  <u-tooltip :offset="[5,5]">Remover</u-tooltip>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="m-t-sm">
            <e-btn @click="adicionarParcela"><i class="fal fa-plus m-r-sm"></i> Adicionar parcela</e-btn>
          </div>
          <legend></legend>
          <e-row class="resumo-rateio">
            <e-col class="flex items-center justify-end">
              <div class="m-l-xl">
                <div class="m-b"><erp-s-field label="Total das parcelas" only-label /></div>
                <div class="rateio-lbl text-right"><small>R$</small> {{somaTotalParcelas|moeda}}</div>
              </div>
              <div class="m-l-xl">
                <div class="m-b"><erp-s-field label="Diferença para o valor do lançamento" only-label /></div>
                <div class="rateio-lbl text-right" :class="{'text-positive': diferencaSomaParcelasValorConta > 0, 'text-negative': diferencaSomaParcelasValorConta < 0}"><small>R$</small> {{diferencaSomaParcelasValorConta|moeda}}</div>
              </div>
            </e-col>
          </e-row>
        </div>
        <e-row class="m-t-sm" mr>
          <e-col>
            <erp-s-field label="Observações">
              <textarea class="app-input input-textarea full-width" rows="4" v-model="conta.observacoes"/>
            </erp-s-field>
            <p class="font-11 font-thin">Descreva observações relevantes sobre esse lançamento financeiro</p>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <legend>Anexos</legend>
        <e-row class="m-t-sm" mr>
          <e-col>
            <div class="erp-s-field">
              <div class="erp-s-field-label">Anexos
                <u-icon name="help" class="text-blue-grey-5 font-14">
                  <u-tooltip :offset="[4,4]">
                    Use o anexo de arquivos para manter comprovantes e arquivos digitais importantes relacionados.
                  </u-tooltip>
                </u-icon>
              </div>
            </div>
            <div class="erp-s-field-content m-t-sm">
              <anexo-conta-input ref="uploader" :conta="conta"/>
            </div>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" :label="'Salvar ' + label" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right"/>
        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
          <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                     self="top right" :offset="[0, 0]">
            <div>
              <ul>
                <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
  </window-content>
</template>

<script>
// @TODO: <V-2.2>: Tags
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'

import {newConta, updateConta} from "@/domain/financeiro/services"
//import CategoriaInput from "@/components/financeiro/components/input/CategoriaInput"
import BancoInput from "@/components/financeiro/components/input/BancoInput"
import DatetimeInput from "@/reuse/input/Datetime"
import mockConta, {mockDetalhe, mockParcela} from "@/domain/financeiro/helpers/mockConta"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import {REPETIR} from "@/domain/financeiro/helpers/types"
import FinPessoaInput from "@/components/financeiro/components/input/PessoaInput"
import CentroCustoInput from "@/components/financeiro/components/input/CentroCustoInput"
import AnexoContaInput from "@/components/financeiro/components/input/AnexoContaInput"
import globalFinancialMixin from "@/components/financeiro/globalFinancialMixin"
import {datePtToEn} from "@/utils/date"
import FormaPagamentoInput from "@/components/financeiro/components/input/FormaPagamentoInput"
import {findConta} from "@/domain/financeiro/services"
import CategoriaHirerarquiaSelect from "@/components/financeiro/components/input/CategoriaHirerarquiaSelect";
import GlobalProcessoInput from "@/components/processos/components/processos/include/ProcessoInput"
import globalProcessoMixin from "@/components/processos/globalProcessoMixin"
import FinDescricaoInput from "components/financeiro/components/input/DescricaoInput"
import SlCheckbox from "@/reuse/input/Checkbox"
import DetalheValorInput from "components/financeiro/components/input/DetalheValorInput"
import {addMonths, format, parseISO} from "date-fns"
import windowConta from "components/financeiro/windows/conta"

export default {
  name: 'ContaWindowModern',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [globalFinancialMixin, globalProcessoMixin],
  directives: {money: VMoney},
  components: {
    DetalheValorInput,
    SlCheckbox,
    FinDescricaoInput,
    GlobalProcessoInput,
    CategoriaHirerarquiaSelect,
    FormaPagamentoInput,
    AnexoContaInput,
    CentroCustoInput,
    FinPessoaInput,
    DatetimeInput,
    BancoInput,
    // CategoriaInput,
    // AutocompleteMultiple,
    // Autocomplete,
    // WindowLoading,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    ErpSelect,
    UTooltip,
    MenuOptionsItem,
    UPopover,
    WindowContent
    // TimerCloseWindow,
    // WindowSuccess,
    // ErpCheckbox
  },
  props: ['id', 'options', 'router', 'tipo', 'entidade', 'entidadeId', 'pessoa', 'processo', 'extraRelations', 'tags'],
  data() {
    return {
      money: REAL_BRL,
      percent: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      loading: false,
      success: false,
      status: '',
      preventBind: true,
      isPago: false,
      cache: {
        parcelas: [JSON.parse(JSON.stringify(mockParcela))]
      },
      conta: this.getMock()
    }
  },
  mounted() {
    console.log(this.tipo)
    if (this.isEditing) {
      this.load()
    } else {
      this.preventBind = false
    }
    if (this.processo) {
      this.conta.processo = this.processo
    }
  },
  watch: {
    'conta.pago'(v) {
      if (!v && this.isPago) {
        this.$uloc.dialog({
          title: 'Cancelar registro já pago',
          message: 'Esta conta já foi definida como paga e houve o lançamento contábil, ao definir como não pago um lançamento de estorno será realizado',
          ok: 'Sim, estornar pagamento',
          cancel: 'Não'
        }).then(() => {

        }).catch(() => {
          this.conta.pago = true
        })
      }
    },
    'conta.rateio' (v) {
      if (v) {
        // Rateio ativado
        if (!this.conta.detalhes || !this.conta.detalhes.length) {
          if (!Array.isArray(this.conta.detalhes)) {
            this.conta.detalhes = []
          }
          this.conta.detalhes.push(JSON.parse(JSON.stringify(mockDetalhe)))
        }
      } else {
        // Rateio desativado
      }
    },
    'conta.repetir' (v) {
      if (v) {
        this.conta.parcelado = false
        this.conta.parcelas = 1
      }
    },
    'conta.parcelado' (v) {
      this.$nextTick(() => {
        this.ajustaVencimentoParcelas()
      })
    },
    'conta.parcelas' (v) {
      v = Number(v)
      let totalParcelasAtual = this.cache.parcelas.length
      console.log(totalParcelasAtual, v)
      if (v > totalParcelasAtual) {
        for (let i = totalParcelasAtual; i < v; i++) {
          this.cache.parcelas.push(JSON.parse(JSON.stringify(mockParcela)))
        }
      } else {
        this.cache.parcelas.splice(this.cache.parcelas.length - (totalParcelasAtual - v), (totalParcelasAtual - v))
      }
      this.ajustaVencimentoParcelas()
    },
    'conta.dataVencimento' (v) {
      if (this.hasParcelas) {
        if (v.length === 10) {
          this.cache.parcelas[0].vencimento = v
        }
      }
    }
  },
  computed: {
    tipoInt() {
      return this.isEditing ? this.conta.tipo : (this.tipo === 'r' ? 1 : 2)
    },
    isEditing() {
      return this.id || (this.conta && this.conta.id)
    },
    getTipoString() {
      if (this.conta && this.conta.id) {
        return Number(this.conta.tipo) === 1 ? 'r' : 'd'
      }
      return this.tipo
    },
    label() {
      let t = this.getTipoString
      return t === 'r' ? 'Recebimento' : 'Pagamento'
    },
    labelTipoPessoa() {
      return this.getTipoString === 'r' ? 'Cliente' : 'Fornecedor'
    },
    repetirTypes() {
      return Object.keys(REPETIR).map(k => {
        return {
          label: REPETIR[k].title,
          value: Number(k)
        }
      })
    },
    valorConta () {
      return convertRealToMoney(this.conta.valor)
    },
    somaTotalRateio () {
      let n = 0
      if (Array.isArray(this.conta.detalhes)) {
        this.conta.detalhes.map(d => {
          n = n + convertRealToMoney(d.valor)
        })
      }
      return n
    },
    restanteTotalRateio () {
      return this.valorConta - this.somaTotalRateio
    },
    rateioPctRestante () {
      const n = (this.restanteTotalRateio / this.valorConta) * 100
      if (isNaN(n)) {
        return 100
      }
      return n
    },
    rateioPctRestanteFormatado () {
      const n = this.rateioPctRestante
      if (n === 100) {
        return 100
      }
      if (parseFloat(n) === 0) {
        return 0
      }
      let decimals = String(n).split('.')
      if (decimals.length > 1 && decimals[1].length < 3) {
        return n
      }
      return Number(n).toFixed(3)
    },
    somaTotalParcelas () {
      let n = 0
      if (Array.isArray(this.cache.parcelas)) {
        this.cache.parcelas.map(p => {
          n = n + convertRealToMoney(p.valor)
        })
      }
      return n
    },
    diferencaSomaParcelasValorConta () {
      return this.somaTotalParcelas - this.valorConta
    },
    hasParcelas () {
      return Array.isArray(this.cache.parcelas) && this.cache.parcelas.length
    }
  },
  methods: {
    getMock() {
      let mock = JSON.parse(JSON.stringify(mockConta))
      const hoje = this.$options.filters.formatDateNoIso(new Date(), 'dd/MM/yyyy')
      mock.dataPagamento = hoje
      mock.dataCompetencia = hoje
      mock.tipo = this.tipo === 'r' ? 1 : 2
      if (this.entidade && this.entidadeId) {
        mock.entidade = this.entidade
        mock.entidadeId = this.entidadeId
      }
      if (this.pessoa) {
        mock.pessoa = this.pessoa
      }
      if (this.extraRelations) {
        mock.extraRelations = this.extraRelations
      }
      if (this.tags) {
        mock.tags = this.tags
      }
      return mock
    },
    load() {
      this.loading = true
      findConta(this.isEditing)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.conta.id = response.data.id
              if (Number(response.data.tipo) === 1) {
                this.$root.tipo = 'r'
              } else {
                this.$root.tipo = 'd'
              }
            }
            this.preventBind = true
            this.conta = Object.assign({}, this.conta, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            // this.task = response.data
            console.log(this.conta)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.conta))
      data.confirmacoes = {
        alterarTodosRegistrosRecorrentes: false
      }
      const valores = ['valor', 'valorPago', 'descontos', 'jurosMulta']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })

      if (data.valor === null || data.valor === '') {
        data.valor = 0
      }

      if (data.dataCompetencia) {
        data.dataCompetencia = datePtToEn(data.dataCompetencia)
      }
      if (data.dataVencimento) {
        data.dataVencimento = datePtToEn(data.dataVencimento)
      }
      if (data.dataPagamento) {
        data.dataPagamento = datePtToEn(data.dataPagamento)
        if (!data.pago) {
          data.dataPagamento = null
        }
      }
      if (Array.isArray(data.detalhes) && data.detalhes.length) {
        data.detalhes = data.detalhes.map(d => {
          d.valor = convertRealToMoney(d.valor)
          return d
        })
      }
      if (Array.isArray(this.cache.parcelas) && this.cache.parcelas.length) {
        data.parcelas = JSON.parse(JSON.stringify(this.cache.parcelas))
        data.parcelas.map((p, i) => {
          p.parcela = i + 1
          p.valor = convertRealToMoney(p.valor)
          p.vencimento = datePtToEn(p.vencimento)
          return p
        })
      }
      const relacoes = ['pessoa', 'centroCusto', 'conta', 'categoria', 'formaPagamento', 'status', 'processo']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      return data
    },
    prepareToEdit(data) {
      const valores = ['valor', 'valorPago', 'descontos', 'jurosMulta']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })
      const datas = ['dataCompetencia', 'dataVencimento', 'dataPagamento']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })
      if (data.categoria && data.categoria.id) {
        data.categoria = {
          ...data.categoria,
          label: data.categoria.descricao,
          value: data.categoria.id
        }
      }
      if (Array.isArray(data.detalhes) && data.detalhes.length) {
        data.detalhes = data.detalhes.map(d => {
          d.valor = this.$options.filters.moeda(d.valor)
          return d
        })
      }
      if (Array.isArray(data.movimentacoesRelacionadas) && data.movimentacoesRelacionadas.length) {
        this.cache.parcelas = data.movimentacoesRelacionadas.map(m => {
          return {
            id: m.id,
            parcela: m.parcela,
            vencimento: format(parseISO(m.dataVencimento.date), 'dd/MM/yyyy'),
            valor: this.$options.filters.moeda(m.valor),
            pago: m.pago,
            dataPagamento: m.dataPagamento
          }
        })
      }
      if (data.pago) {
        this.isPago = true
      }
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {

        if (this.$appConfig.isConfigTrue('financeiro.forcarAnexoAoPagar')) {
          if (data.pago && !this.$refs.uploader.existsFilesToUpload && (!data.anexos || !data.anexos.length)) {
            this.alertApiError(null, 'Erro de validação', null, `É obrigatório anexar um arquivo para definir um lançamento como pago.`)
            this.loading = false
            return
          }
        }
        let method = newConta
        if (this.conta.id) {
          method = updateConta
        }
        method(data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.conta.id = response.data.id
              this.$nextTick(() => {
                this.$refs.uploader && this.$refs.uploader.iniciaEnvios()
              })
              // this.$uloc.window.close(this.$root.wid)
              if (isEditing) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                if (type === 'andEdit') {
                  this.dg()
                } else {
                  this.$uloc.notify({
                    color: 'positive',
                    message: `${this.label} com ID #${response.data.id} alterado.`,
                    position: 'bottom-right',
                    /*actions: [{
                      label: 'Visualizar',
                      handler: () => {
                        this.gotoPerson(response.data)
                      }
                    }]*/
                  })
                }
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `Novo ${this.label} com ID #${response.data.id} criado.`,
                  position: 'bottom-right',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
              if ((!this.$refs.uploader || (this.$refs.uploader && !this.$refs.uploader.existsFilesToUpload)) && (type === 'andClose' || (this.entidade && this.entidadeId))) {
                this.$uloc.window.close(this.$root.wid)
              } else if (type === 'andNew') {
                this.preventBind = true
                this.conta = this.getMock()
                this.$nextTick(() => {
                  this.preventBind = false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      let confirmacoes = []
      if (data.recorrencia && data.recorrencia.id) {
        await this.confirmarAlteracaoRecorrencia(data)
      }
      submit()
    },
    async confirmarAlteracaoRecorrencia (data) {
      return new Promise((resolve) => {
        this.$uloc.dialog({
          title: 'Lançamento recorrente',
          message: 'Como este lançamento possui recorrência, deseja alterar somente este lançamento ou alterar todos os lançamentos futuros?',
          ok: 'Somente este',
          cancel: 'Alterar todos',
          color: 'primary'
        }).then(() => {
          resolve(data)
        }).catch(() => {
          data.confirmacoes.alterarTodosRegistrosRecorrentes = true
          resolve(data)
        })
      })
    },
    adicionarRateio () {
      console.log('Adicionando detalhe')
      this.conta.detalhes.push(JSON.parse(JSON.stringify(mockDetalhe)))
    },
    removerDetalheRateio (d) {
      this.conta.detalhes.splice(this.conta.detalhes.indexOf(d), 1)
    },
    defineValorDetalhe: function(d, k) {
      clearTimeout(this['timeoutDetalheUpdate' + k])
      /* if (this['preventDetalheUpdate' + k]) return
      this['preventDetalheUpdate' + k] = true
      setTimeout(() => {
        this['preventDetalheUpdate' + k] = false
      }, 100) */
      this['timeoutDetalheUpdate' + k] = setTimeout(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            const v = this.$options.filters.moeda((convertRealToMoney(d.valor) / this.valorConta) * 100)
            if (isNaN(v) || !isFinite(v)) {
              // return
            }
            d.porcentagem = v
          }, 100)
        })
      }, 100)
    },
    definePorcentagemDetalhe: function(d, k) {
      clearTimeout(this['timeoutDetalhePctUpdate' + k])
      this['timeoutDetalhePctUpdate' + k] = setTimeout(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            const v = this.$options.filters.moeda(this.valorConta * (convertRealToMoney(d.porcentagem) / 100))
            d.valor = v
          }, 30)
        })
      }, 30)
      // d.valor = v
    },
    adicionarParcela () {
      this.conta.parcelas = Number(this.conta.parcelas) + 1
    },
    removerParcela (p) {
      this.cache.parcelas.splice(this.cache.parcelas.indexOf(p), 1)
      this.conta.parcelas = Number(this.conta.parcelas) - 1
    },
    ajustaVencimentoParcelas () {
      if (!this.cache.parcelas || !this.cache.parcelas.length) {
        return
      }
      let primeiroVendimento = this.conta.dataVencimento
      if (!primeiroVendimento) {
        this.$uloc.dialog({
          title: 'Atenção',
          message: 'Informe a data do primeiro vencimento para gerar as demais datas automaticamente.'
        })
      }
      const totalParcelas = this.cache.parcelas.length
      primeiroVendimento = parseISO(datePtToEn(primeiroVendimento))
      let proximoVencimento = primeiroVendimento
      const valorTotal = convertRealToMoney(this.conta.valor)
      const valorPorParcela = convertRealToMoney(this.$options.filters.moeda(valorTotal / totalParcelas)) // To fix two decimal places
      this.cache.parcelas.map((parcela, index) => {
        if (parcela.id) {
          return
        }
        if (index > 0) {
          proximoVencimento = addMonths(proximoVencimento, 1)
        }
        parcela.vencimento = format(proximoVencimento, 'dd/MM/yyyy')
        parcela.valor = this.$options.filters.moeda(valorPorParcela)
      })
      this.$nextTick(() => {
        // Ajusta a última parcela com o saldo restante
        this.cache.parcelas[this.cache.parcelas.length - 1].valor = this.$options.filters.moeda(valorPorParcela + (valorTotal - (valorPorParcela * totalParcelas)))
      })
    },
    windowConta: windowConta
  }
}
</script>
